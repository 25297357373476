<template>
  <div class="login">
    <dynamic-form :controls="loginControls" submit="Войти" @submit="loginRequest">
      <template v-slot:header>
        <p>Введите ваши данные</p>
      </template>
      <template v-slot:errors>
        <div v-if="loginError === 407">
          <p>Добрый день. </p>
          <p>Для доступа в систему используйте мобильное приложение.</p>
          <p>Приложение можно скачать по ссылкам:</p>
          <p><a href="https://apps.apple.com/ru/app/max-enterprise/id1528669492" target="_blank">iOS</a></p>
          <p><a href="https://play.google.com/store/apps/details?id=com.max_moscow.maxenterprise" target="_blank">Android</a>
          </p>
        </div>
        <div v-else>{{ loginError }}</div>
      </template>
    </dynamic-form>

    <v-snackbar class="download-snackbar" :value="true" timeout="-1" color="#81818121">
      <v-container fluid class="px-0 py-1">
        <v-row align="center" justify="center">
          <v-col class="py-0" cols="12" sm="auto">
            <p class="text-center ma-0">Скачать мобильное приложение для платформы Android</p>
          </v-col>
          <v-spacer/>
          <v-col class="py-0" cols="auto">
            <v-btn class="btn-download" text link>
				    <a href="https://mutant.hb.bizmrg.com/app-release.apk" target="_blank" type="application/vnd.android.package-archive">Скачать</a></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-snackbar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import DynamicForm from "../components/common/dynamic-form/dynamic-form";
import Rules from "../components/common/dynamic-form/rules";
import {Controls} from "../components/common/dynamic-form/controls";
import {User} from "../models/entities/User";

export default {
  name: "login",
  components: {DynamicForm},
  computed: mapGetters({
    userRole: "userRole",
    userId: "userId",
    roleType: "roleType",
    returnPath: "returnPath",
    accesses: "accesses"
  }),
  data: function () {
    return {
      loginControls: [
        Controls.TextField('login').AddSettings({placeholder: 'Логин'}).AddRules({
          rules: Rules.Login(),
          validateOnBlur: true
        }),
        Controls.TextField('password').AddSettings({
          placeholder: 'Пароль',
          password: true,
          displayEye: true
        }).AddRules({rules: Rules.Required(), validateOnBlur: false})
      ],
      loginError: null,
    }
  },
  methods: {
    ...mapActions({login: "login", setReturnPath: "setReturnPath"}),
    loginRequest(user) {
      this.loginError = null;
      this.login(user)
          .then(() => {
            let route = '/reports'

            if(this.roleType !== 'admin') {
              const routes = this.$router.options.routes
                  .filter(r => !!r.meta?.sideBar)
                  .filter(r => this.accesses.map(a => a.key).includes(r.meta.accessKey))
              route = routes.length !== 0 ? routes[0].path : {name: 'forbidden'}
            }

            const path = this.returnPath ?? route

            this.$router.push(path)
                .then(() => this.setReturnPath(undefined))
          })
          .catch(e => {
                this.loginError = e;
                user.password = atob(user.password)
              }
          );
    },
  }
}
</script>

<style scoped>
.login {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.download-snackbar >>> p, button {
  color: rgba(0, 0, 0, 0.87) !important;
}

.download-snackbar >>> .v-snack__action {
  display: none;
}
</style>
